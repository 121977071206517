import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/Layout";
import * as S from "../../styles/mesagePage/styles";

const Success = () => {
  return (
    <Layout>
      <S.PageWrapper>
        <div className="container">
          <h1>Obrigado</h1>
          <p>Obrigado! Sua sugestão foi enviada com sucesso. Iremos analisar a possibilidade do tema sugerido.</p>
          <Link to="/">
            <p className="btnP">Voltar</p>
          </Link>
        </div>
      </S.PageWrapper>
    </Layout>
  );
};

export default Success;
